.footer {
    text-transform: uppercase;
    /*font-weight: 700;*/
    font-size: 0.82em;
}

.footer .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}



.footer ul.nav {
    margin-left: -0.5rem;
}
.footer ul.nav li.copy div {
    padding: 0.5rem 1rem 0.5rem 0.5rem;
}
@media (min-width: 768px) and (max-width: 991.98px) { 
    .footer ul.nav li.copy {
        width: 100%;
        display: block;
    }
    .footer ul.nav li.copy div {
        padding-bottom: 0;
    }
}
@media (max-width: 767.98px) {
    .footer ul.nav li:not(.copy) {
        display: none;
    }
}

.footer ul.nav .nav-icon {
    padding-top: 0.3rem;
}

.footer ul.nav .nav-icon .ty-social-icon {
    width:20px;
}
.footer ul.nav .nav-icon .social-cls-1 {
    fill: #999 !important;
}
.footer ul.nav .nav-link:hover .social-cls-1 {
    fill: #cbd3da !important;
}