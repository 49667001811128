.ar-container {
    width:100%;
}
.ar3d {
    min-height: 80vh;
}
.ar3d.mobile {
    min-height: 60vh;
    margin-left: auto !important;
    margin-right: auto !important;
}
.arexternal {
    min-height: 60vh;
}
.arexternal.mobile {
    min-height: auto;
    padding-bottom:3rem;
}
@media (min-width: 768px) {
    .ar3d {
        margin-left: -1.5rem !important;
        position: sticky;
        top: 0;
        flex: 1;
        display: flex;
        justify-content: center;
    }
    .ar3d.mobile {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
/*
@media (min-width: 992px) {
    .ar3d {
        min-height: 100vh;
    }
}
*/


.ar3d model-viewer {
    width: 100%;
    height: 100%;
    background-color: #eee;
    overflow-x: hidden;
    --poster-color: #eee;
}

.ar3d model-viewer :not(:defined) > * {
    display: none;
}


.ar-btn {
    position: absolute;
    bottom: 70px;
    left: 50%;
    width: 230px;
    margin-left: -115px;
    padding: 5px 20px;
}
.ar-btn-external {
    width: 230px;
    padding: 5px 20px;
}
@media (min-width: 768px) {
    .ar-btn-external,
    .ar-btn {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
.ar-btn-external svg,
.ar-btn svg {
    width:40px;
    padding-right: 10px;
}
.ar-btn-external svg .st3-ar,
.ar-btn svg .st3-ar {
    stroke: #fff;
}

.ar-info {
    max-width:600px;


    /*
    padding-top: 110px !important;
    background-image: url(../assets/ic_view_in_ar_new_googblue_48dp.png);
    background-repeat: no-repeat;
    background-size: 72px 72px;
    background-position: 50% 20px;
    */
}

.ar-qrcode {
    height: auto !important;
    width: 80% !important;
    max-width: 300px;
}
