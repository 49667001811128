.fullwide {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}



/* AR */
.ar3d-btn {
    background-image: url(../assets/ic_view_in_ar_new_googwhite_48dp.png);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 40px 50%;
}