.bio {
    z-index: 2;
}


.title {
    font-size:7em;
    text-align: right;
    margin-top: 2rem;
    margin-right: -7rem;
    z-index: 99;
    background-color: white;
    padding:30px;
    line-height: 0.9em;
}
.subtitle {
    font-size: 2em;
    text-align: right;
}
.desc {
    font-size: 1.1em;
}
@media (min-width: 1200px) {
    .title {
        font-size:10em;
    }
    .subtitle {
        font-size: 3em;
    }
    .desc {
        font-size: 1.3em;
    }
}


.unilist {
    font-size: 1em;
}


@media (min-width: 992px) { 
    .desc,
    .unilist {
        float: right !important;
        text-align: right !important;
    }
}


.designer-img {
    z-index: 1;
}
.logoholder {
    display: inline-block;
}
.logoholder img {
    max-width: 230px;
    max-height: 120px;
}
.logoholder-sm {
    display: block;
    max-width: 100%;
    max-height: 100%;
}
.logoholder-sm img {
    display: block;
    max-width: 100%;
    max-height: 120px;
    margin-left: auto;
    margin-right: auto;
}
