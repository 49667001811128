.ty-header {
    font-size: 2em;
}
@media (min-width: 768px) { 
    .ty-header {
        font-size: 3em;
    }
}
.ty #lottie {
    height: 100px;
    width: 100%;
    margin:0;
}
@media (min-width: 768px) { 
    .ty #lottie {
        height: 300px;
    }
}

.ty-message {
    max-width: 700px;
    width:100%;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 768px) { 
    .ty-message {
        width:80%;
    }
}

.ty-social-icon {
    width:50px;
}