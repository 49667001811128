@media (min-width: 1200px) {
    .story .mb-5, .story .my-5 {
      margin-bottom: 4rem !important;
    }
    .story .mt-5, .story .my-5 {
      margin-top: 4rem !important;
    }
}
  

.story h1, .story h2 {
    font-weight: 100;
}
.story h1 {
    font-size: 1.7rem;
    text-align: center;
    text-transform: uppercase;
    margin-right: auto;
    margin-left: auto;
}
.story h2 {
    font-size: 1.2rem;
    text-transform: uppercase;
}
.story .lead {
    font-size: 1rem;
}
.story .lead.lrg {
    font-size: 1.3rem;
    text-transform: uppercase;
    line-height: 1.2;
}
.story .lead.sml {
    font-size: 0.85rem !important;
    font-weight: 400;
}
@media (min-width: 768px) {
    .story h1 {
        max-width: 1000px;
        font-size: 2rem;
    }
    .story h2 {
        font-size: 1.5rem;
    }
    .story .lead {
        font-size: 1.1rem;
    }
    .story .lead.lrg {
        font-size: 1.5rem;
    }
}
@media (min-width: 992px) {
    .story h1 {
        font-size: 3rem;
    }
    .story h2 {
        font-size: 2rem;
    }
    .story .lead {
        font-size: 1.25rem;
    }
   .story .lead.lrg {
        font-size: 2rem;
    }
}
@media (min-width: 1200px) {
    .story h1 {
        font-size: 5rem;
    }
    .story h2 {
        font-size: 3rem;
    }
   .story .lead.lrg {
        font-size: 3rem;
    }
}

.story .banner {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}
.story .vid-fullscreen {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}
@media (min-width: 768px) {
    .story .dualgalleryitem,
    .story .sidetxt {
        max-width: 80%;
    }
    .story .leadbox {
        max-width: 65%;
    }
    .story .sidetxt.smallbox {
        max-width: 450px;
    }
}


  .story .txt-columns {
    -webkit-columns: 2 200px;
       -moz-columns: 2 200px;
            columns: 2 200px;
    -webkit-column-gap: 4em;
       -moz-column-gap: 4em;
            column-gap: 4em;
  }


.story .fixed-bg {
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}
.story .fixed-bg.mobile {
    background-attachment: scroll !important;
}


.story .dualgalleryitem img {
    width: 60%;
}

.story .gallery {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}
.story .gallery {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}
.story .gallery .inner {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
}


.story .textbox {
    max-width: 600px;
}
.story .textbox.smallboxs {
    max-width: 400px;
}




.story .story-images-masonry {
    column-count: 1 !important;
}
@media (min-width: 992px) {
    .story .story-images-masonry {
        column-count: 2 !important;
    }
}





.story .vjs-poster {
    background-color: #fff !important;
}





.story .story-images-carousel {
    position: relative;
}
.story .story-images-carousel .BrainhubCarouselItem {
    align-items: flex-end !important;
}
.story .story-images-carousel .BrainhubCarousel__customArrows {
    position: absolute !important;
    width: 100%;
    z-index:10;
}
.story .story-images-carousel .BrainhubCarousel__customArrows button {
    position: absolute;
    border: 0;
    background: none;
    outline: none;
    opacity: 0.3;
}
.story .story-images-carousel .BrainhubCarousel__customArrows button:hover {
    opacity: 0.5;
}
.story .story-images-carousel .BrainhubCarousel__customArrows button.carousel-control-left {
    top: 50%;
    left: 10px;
}
.story .story-images-carousel .BrainhubCarousel__customArrows button.carousel-control-right {
    top: 50%;
    right: 10px;
}
.story .story-images-carousel .carousel-control-prev-icon, 
.story .story-images-carousel .carousel-control-next-icon {
    width: 30px;
    height: 30px;
}
@media (min-width: 768px) {
    .story .story-images-carousel .BrainhubCarousel__customArrows button.carousel-control-left {
        left: 10px;
    }
    .story .story-images-carousel .BrainhubCarousel__customArrows button.carousel-control-right {
        right: 10px;
    }
    .story .story-images-carousel .carousel-control-prev-icon, 
    .story .story-images-carousel .carousel-control-next-icon {
        width: 50px;
        height: 50px;
    }
}
@media (min-width: 992px) {
    .story .story-images-carousel .BrainhubCarousel__customArrows button {
        opacity: 0.2;
    }
    .story .story-images-carousel .BrainhubCarousel__customArrows button:hover {
        opacity: 0.4;
    }
    .story .story-images-carousel .BrainhubCarousel__customArrows button.carousel-control-left {
        left: 20px;
    }
    .story .story-images-carousel .BrainhubCarousel__customArrows button.carousel-control-right {
        right: 20px;
    }
    .story .story-images-carousel .carousel-control-prev-icon, 
    .story .story-images-carousel .carousel-control-next-icon {
        width: 70px;
        height: 70px;
    }
}
@media (min-width: 1200px) {
    .story .story-images-carousel .carousel-control-prev-icon, 
    .story .story-images-carousel .carousel-control-next-icon {
        width: 100px;
        height: 100px;
    }
}





@media (min-width: 768px) {
    .story .text-paragraph {
        max-width: 450px;
    }
    .story .text-paragraph.lrg {
        max-width: 650px;
    }
}
.story .text-paragraph {
    line-height: 1.6em;
}
.story .text-paragraph p:first-of-type {
    margin-top: 0;
}

.story .text-paragraph p:last-of-type {
    margin-bottom: 0;
}
