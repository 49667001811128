.carousel-home {
    margin-top: -75px;
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}
.carousel-home .carousel-caption {
    display: block !important;
}
.carousel-home .carousel-caption p {
    margin-bottom: 0 !important;
    font-size: 1rem;
}
@media (max-width: 767.98px) { 
    .carousel-home .carousel-caption {
        padding-bottom: 15px !important;
    }
}





.card.basic {
    border: 0;
    border-radius: 0;
}
.card.basic .card-body{
    padding-left: 0;
    padding-right: 0.8rem;
}
.card.basic .card-subtitle,
.card.basic .card-title {
    font-size: 1em;
}
.card.basic .card-text {
    
}
.card.basic a.more,
.card.basic .card-subtitle a {
    color: #999 !important;
    font-size: 0.85em;
}




/*
#featuredesigner {
    background-color: #f4f4f4;
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    position: relative;
}
#featuredesigner .ftitle {
    padding-top: 1.5rem;
}
@media (min-width: 992px) { 
    #featuredesigner .ftitle {
        top: 10px;
        padding-top: 0;
    }
}
@media (min-width: 1200px) { 
    #featuredesigner .ftitle {
        top: -60px;
    }
}
@media (max-width: 991.98px) {
    #featuredesigner .lead {
        font-size: 1rem !important;
    }
}*/




@media (max-width: 767.98px) { 
    #listd-section .txtblock h1 {
        max-width: 400px;
    
    }
}



.homep-b {
    position: relative;
    overflow: hidden;
}
.homep-b-txt {
    position: absolute;
    right:0;
    top:400px;
    width:500px;
    text-transform: uppercase;
    font-size:2.5rem;
    line-height: 1;
    width:70%;
    background-color: #fff;
    padding:20px;
    z-index: 2;
}
.homep-b-credit {
    position: absolute;
    padding: 10px; 
    font-size: 9.184px;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.homep-enter {
    font-size: 1.8rem;
    line-height: 1;
}
@media (orientation: landscape) and (max-width: 767.99px) {
    .homep-b-txt {
        top:25%;
        right: 10%;
        max-width:250px;
        background:none;
        padding:0;
    }
    .homep-enter {
        font-size: 1.7rem;
        line-height: 1.2;
    }
}
@media (orientation: portrait) and (max-width: 767.99px) {
    .homep-b-credit {
        bottom: 0;
        right: 0;
        left: unset;
    }
}
@media (min-width: 768px) {
    .homep-b-txt {
        top:32%;
        right: 10%;
        width:300px;
        font-size:3rem;
        background:none;
        padding:0;
    }
    .homep-enter {
        font-size: 1.7rem;
        line-height: 1.2;
    }
}
@media (min-width: 1200px) {
    .homep-b-txt {
        right:15%;
        top:240px;
        width:400px;
        font-size:4rem;
        background:none;
        padding:0;
    }
    .homep-enter {
        font-size: 2rem;
        line-height: 1.2;
    }
}
@media (min-width: 1350px) {
    .homep-b-txt {
        right:10%;
        top:260px;
        width:500px;
        font-size:4.5rem;
    }
    .homep-enter {
        font-size: 2.2rem;
    }
}
@media (min-width: 1500px) {
    .homep-b-txt {
        right:15%;
        top:260px;
        width:500px;
        font-size:5rem;
    }
    .homep-enter {
        font-size: 2.5rem;
    }
}


/*
.homep-b-txt {
    position: absolute;
    right:15%;
    top:30%;
    font-size:80px;
    width:500px;
    height: 400px;
    text-transform: uppercase;
    line-height: 1em;

}
.homep-enter {
    font-size: 40px;
}*/