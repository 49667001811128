.custom-element {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 3px);
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 768px) {
  .custom-element {
    height: calc(1.5em + 0.75rem + 1px);
  }
}


.custom-element.ideal-bank-element,
.custom-element.payment-request-button {
  padding: 0;
}

.custom-lbl {
  display: inline-block;
  margin-bottom: 0.5rem;
}