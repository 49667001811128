.simple-qa {
    max-width:900px;
}
.simple-qa section {
    padding-bottom: 5px;
}
.simple-qa a.q {
    font-size: 1.1em;
    position: relative;
    padding-left:30px;
    padding-right: 20px;
    /*background-color: #eaeaea;*/
}
.simple-qa a.q::before {
    content: ' ';
    position: absolute;
    top:50%;
    left: 10px;
    background: no-repeat 50% / 100% 100%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    background-size: 10px;
    width:10px;
    height:10px;
    margin-top:-5px;
    opacity: 0.5;
    transition: all 0.5s;
}
.simple-qa a.q.active::before,
.simple-qa a.q:active::before,
.simple-qa a.q:hover::before {
    opacity: 0.9;
}
.simple-qa a.q.active::before {
    opacity: 0.9;
    -webkit-transform:rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}