.mailinglist {
    width:300px;
  
}

.mailinglist-btn,
.mailinglist-email {
    height: 25px !important;
}
.mailinglist-btn {
    display: block;
    float: right;
    width: calc(40% - 10px) !important;
    margin-left: 10px !important;
}
.mailinglist-email {
    padding: 0.16rem 0.5rem !important;
    display: block;
    width:60% !important;
    float: left;
    color: black !important;
    font-size: 0.82rem !important;
    font-weight: 300 !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.mailinglist input[name="newsletterInterestOption"] {
	accent-color: #000;
}
