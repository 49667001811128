.wc-sidetxt {
    width:100%;
    max-width: 450px;
}
.wc-lottie {
    width:200px;
}
.wc-qr {
    height: auto !important;
    width: 100% !important;
    max-width: 450px;
}
@media (min-width: 992px) {
    .cw-pay {
        margin-top:3rem;

    }
}
