/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&family=Roboto:wght@100;300;400;700&display=swap');

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

body {
  /*font-family: 'Open Sans', sans-serif;*/
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color:#000;
  font-weight: 300;
  font-stretch: 75%;
  letter-spacing: 0.2px;
  line-height: 1.2;
}
@media (min-width: 768px) {
  body {
    font-size: 0.85rem;
  }
}
html, body, #root, #root>div {
  height: auto;
}
#main {
  min-height: 81vh;
}

h1 {
  font-size: 1.3rem;
}
h2 {
  font-size: 1.15rem;
}
h3 {
  font-size: 1rem;
}
h4 {
  font-size: 0.92rem;
}
h5 {
  font-size: 0.85rem;
}




section {
  position: relative;
}
.ftitle {
  text-transform: uppercase;
  font-size: 1.3rem;
}
.sectiontitle {
  text-transform: uppercase;
}

@media (max-width: 767.98px) {
  .mb-5, .my-5 {
    margin-bottom: 2rem !important;
  }
  .mt-5, .my-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mb-5, .my-5 {
    margin-bottom: 2.5rem !important;
  }
  .mt-5, .my-5 {
    margin-top: 2.5rem !important;
  }
}
@media (min-width: 992px) { 
  .ftitle {
    font-size: 3rem;
    position: absolute;
    top: -20px;
    right: 0;
    z-index: 99;
  }
}
@media (min-width: 1200px) { 
  .ftitle {
    font-size: 5rem;
    top: -20px;
  }
}



.banner {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
  position: relative;
  overflow: hidden;
}
.banner.fixed-bg {
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}
.banner.fixed-bg.mobile {
  background-attachment: scroll !important;
}

.banner a {
  position: absolute;
  bottom: 50px;
  right:50px;
  font-size: 1.3em;
  z-index: 2;
}
.banner a.abg {
  position: absolute;
  bottom: 0;
  top:0;
  left: 0;
  right: 0;
  z-index: 1;
}
@supports (-webkit-overflow-scrolling: touch) {
  .banner.fixed-bg {
      background-attachment: scroll;
  }
}





.motive {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  font-size: 1rem;
  max-width: 500px;
  position: relative;
}
.motive.fslash::before {
  display: none;
}
.motive h1 {
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
}
.motive h3 {
  font-size: 1.1rem;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.4;
}
@media (min-width: 992px) {
  .motive {
    width: auto;
    text-align: left;
    margin-left: 200px;
  }
  .motive.fslash::before {
    display: block;
    content: '/';
    font-size: 8rem;
    height:90px;
    line-height: 90px;
    position: absolute;
    top: 0;
    left: -80px;
  }
  .motive h1 {
    font-size: 2.3rem;
    line-height: 1.1;
    text-align: left;
  }
  .motive h3 {
    font-size: 1.2rem;
    text-align: left;
    line-height: 1.3;
  }
}
@media (min-width: 1200px) { 
  .motive {
    max-width: 600px;
    margin-left: 300px;
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .motive.fslash::before {
    display: block;
    content: '/';
    font-size: 12rem;
    height: 120px;
    line-height: 120px;
    position: absolute;
    top: 0;
    left: -100px;
  }
  .motive h1 {
    font-size: 3rem;
  }
  .motive h3 {
    font-size: 1.4rem;
  }
}


.form-control {
  font-size: 0.85rem;
  border-radius: 0;
  border-color: #000;
  height: calc(1.5em + 0.75rem + 1px);
  color: #000 !important;
}
.custom-select {
  font-size: 0.85rem !important;
  height: calc(2rem + 1px) !important;
}
.btn {
  font-size: 0.85rem;
  border-radius: 0;
  background-color: #000;
  border-color: #000;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.3;
}
.btn.btn-lg, .btn-group-lg > .btn {
  font-size: 0.95rem;
}
.btn-sm, .btn-group-sm > .btn {
  font-size: 0.75rem;
}
.btn.btn-outline-dark {
  background-color: #fff;
  color: #000;
}
.btn.btn-outline-dark:active {
  color: #666;
}
.btn.btn-outline-dark:hover {
  color: #121416 !important;
  background-color: #e9ecef;
}
.btn-label {
  pointer-events: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0px;
  border-radius: 0;
  background-image: url("https://res.cloudinary.com/flairfashion/image/upload/c_scale,dpr_auto,h_128/v1643476600/TestSite/General/arrow-down.png");
  background-position: 96% 50%;
  background-repeat: no-repeat;
  background-size: 10px;
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
  background-size: 8px 10px;
  border: 1px solid #000;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;  
}

::-webkit-input-placeholder {
  color: #999 !important;
}
::-moz-placeholder {
  color: #999 !important;
}
::-ms-placeholder {
  color: #999 !important;
}
::placeholder {
  color: #999 !important;
}
/*
.custom-select {
  color: #999 !important;
}
*/
.form-check-input {
  margin-top: 0.15rem;
}


/* Provide sufficient contrast against white background */
a {
  /*color: #0366d6;*/
  color: #000;
}
a:hover {
  color: #000;
  text-decoration: underline;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #3059AD;
  border-color: #3059AD;
  /*
  background-color: #1b6ec2;
  border-color: #1861ac;
  */
}



.text-light,
.text-light.a {
  color: #999 !important;
}


.fslash::before {
  content: '/ '
}




/* nav tabs */
.nav-tabs .nav-link {
  border-radius: 0;
  border: none;
  position: relative;
  color:#666;
  cursor: pointer;
}
.nav-tabs .nav-link::after {
  content:'';
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  background:transparent;
  height:3px;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  color: #3059AD !important;
}
/*
.nav-tabs .nav-link:hover::after, .nav-tabs .nav-link:focus::after {
  background: #3059AD;
}*/
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #000 !important;
}
.nav-tabs .nav-link.active::after, .nav-tabs .nav-item.show .nav-link::after {
  background: #000;
}



/*** customise video player ***/
.vjs-theme-sea .vjs-progress-control .vjs-play-progress {
  background-color: #3059AD !important;
}
.video-js:hover .vjs-big-play-button, .video-js .vjs-big-play-button:focus {
  background-color: #3059AD !important;
}
.video-js video:focus {
  outline: none;
}




/* SOCIAL MEDIA */
.social-cls-1{
  fill:#000;
}







/*new UI*/
.w-full, .banner-full {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}
.no-pPadding {
  margin-top: -1rem;
}
.sml-sidePaadding {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.banner-full {
  position: relative;
}
  .banner-full .banner-txt {
    position: absolute;
    bottom: -1px;
    left: 0;
    background-color: #fff;
    padding: 10px;
  }
.text-shadow {
  text-shadow: 1px 1px 2px #000;
}
.header-style {
  font-family: 'Playfair Display', serif;
  font-weight: 600;
}
  .header-style .hl {
      color:#747474;
  }


.page-title__h1 {
  font-weight: 500;
  font-size: 2rem;
}
.page-title__h2 {
  font-weight: 500;
  font-size: 1.6rem;
}
@media (min-width: 768px) {
  .page-title__h1 {
    font-weight: 500;
    font-size: 2.5rem;
  }
  .page-title__h2 {
    font-weight: 300;
    font-size: 1.8rem;
  }
}
@media (min-width: 992px) {
  .page-title__h1 {
    font-weight: 500;
    font-size: 3rem;
  }
  .page-title__h2 {
    font-weight: 300;
    font-size: 2rem;
  }
}







.fcarousel {
  position: relative;
}
.fcarousel.infinite-carousel ul.BrainhubCarousel__track {
  margin-left: 100px !important;
}
.fcarousel .BrainhubCarouselItem {
  align-items: flex-start !important;
}
.fcarousel .BrainhubCarousel__customArrows {
  position: absolute !important;
  top:30%;
  width: 100%;
  z-index:10;
  display: none !important;
}
.fcarousel .BrainhubCarousel__customArrows button {
  position: absolute;
  border: 0;
  background: none;
  outline: none;
  opacity: 0.6;
}
.fcarousel .BrainhubCarousel__customArrows button:hover {
  opacity: 0.8;
}
.fcarousel .BrainhubCarousel__customArrows button.carousel-control-left {
  top: 50%;
  left: 10px;
}
.fcarousel .BrainhubCarousel__customArrows button.carousel-control-right {
  top: 50%;
  right: 10px;
}
.fcarousel .carousel-control-prev-icon, 
.fcarousel .carousel-control-next-icon {
  width: 50px;
  height: 50px;
}
@media (min-width: 768px) {
  .fcarousel .BrainhubCarousel__customArrows button.carousel-control-left {
      left: 10px;
  }
  .fcarousel .BrainhubCarousel__customArrows button.carousel-control-right {
      right: 10px;
  }
  .fcarousel .carousel-control-prev-icon, 
  .fcarousel .carousel-control-next-icon {
      width: 50px;
      height: 50px;
  }
}
@media (min-width: 992px) {
  .fcarousel .BrainhubCarousel__customArrows button {
      opacity: 0.2;
  }
  .fcarousel .BrainhubCarousel__customArrows button:hover {
      opacity: 0.8;
  }
  .fcarousel .BrainhubCarousel__customArrows button.carousel-control-left {
      left: 20px;
  }
  .fcarousel .BrainhubCarousel__customArrows button.carousel-control-right {
      right: 20px;
  }
  .fcarousel .carousel-control-prev-icon, 
  .fcarousel .carousel-control-next-icon {
      width: 70px;
      height: 70px;
  }
}
@media (min-width: 1200px) {
  .fcarousel .carousel-control-prev-icon, 
  .fcarousel .carousel-control-next-icon {
      width: 100px;
      height: 100px;
  }
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
}





/* COLLECTIONS */
.collections .item {
  position: relative;
  max-width: 220px;
}
@media (min-width: 768px) { 
  .collections .item {
      max-width: 250px;
  }
}
@media (min-width: 992px) { 
  .collections .item {
      max-width: 300px;
  }
}
.collections .item a {
  color: #fff;
}
.collections .item a:hover {
  color: #86bfff;
}

.collections button {
  background-color: #262624;
  position: relative;
  padding: 21px;
  border: none;
  overflow: hidden;
  outline: 0;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}
.collections button:hover {
  background-color: #8b8c8c;
}
.collections button.disabled {
  background-color: #d9d9d9;
}
.collections button span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
}




/* product listing */
.productlisting {
  margin-left: auto;
  margin-right: auto;
}
.productlisting .row > div {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

@media (min-width: 768px) { 
  .productlisting {
    max-width: 800px;

  }
}
@media (min-width: 992px) { 
  .productlisting {
    max-width: 1000px;

  }
}
@media (min-width: 1200px) {
  .productlisting {
    max-width: 1400px;

  }
}
