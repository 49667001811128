.productlistingitem .product-meta {
    z-index: 99;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
}
.productlistingitem .brand a {
    color:#999;
    font-size: 0.85em;
}

.productlistingitem h1,
.productlistingitem h3 {
    display: block;
    width: 100%;
    margin-bottom:0.2rem;
    text-transform: uppercase;
    text-align: center;
}
.productlistingitem h3 {
    font-size: 1em;
}
.productlistingitem h1 {
    font-size: 1em;
}
.productlistingitem .price {
    display: block;
    width:100%;
    color: #000;
    font-size: 1em;
    text-align: center;
}
