header {
  z-index: 9999 !important;
}

.leftMenu {
  margin-left: -.5rem !important;
}
.rightMenu {
  margin-right: -.5rem !important;
}

.navbar-brand {
  margin-right: 0;
}

.navbar-nav {
  padding-right: 0;
}
.navbar-nav a {
  text-transform: uppercase;
  /*font-weight: 700;*/
  font-size: 0.82em;
  line-height: 1;
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
      display: none !important;
  }
  .navbar-nav a {
    line-height: 2;
  }
}




.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


.logo {
  display: block;
  margin: 0 auto;
  padding: 0;
  width:80px;
  height:40px;
  text-indent: -500%;
  overflow: hidden;
  background: url(https://res.cloudinary.com/flairfashion/image/upload/c_scale,f_auto,h_120,dpr_auto,q_auto:best/v1608440141/TestSite/Logos/FlairLogo-09_lfllpn.png) no-repeat center center;
  background-size: 52px 35px;
}


.abstop {
  z-index: 1999;
}

/* mobile nav */
.nav-overlay-button {
  position: absolute;
  left: 10px;
  top: 0;
  padding: 25px 11px;
  z-index: 1999;
  cursor: pointer;
  user-select: none;
}
.nav-overlay-button span {
  height: 1px;
  width: 25px;
  border-radius: 0px;
  background-color: #000;
  position: relative;
  display: block;
  transition: all .2s ease-in-out;
}
.nav-overlay-button span::before {
  top: -8px;
  visibility: visible;
}
.nav-overlay-button span::after {
  top: 8px;
}
.nav-overlay-button span::before, 
.nav-overlay-button span::after {
    height: 1px;
    width: 25px;
    border-radius: 0px;
    background-color: #000;
    position: absolute;
    content: "";
    transition: all .2s ease-in-out;
}
.nav-overlay-button:hover span, 
.nav-overlay-button:hover span::before, 
.nav-overlay-button:hover span::after {
  background: #333332;
}

input[type=checkbox].nav-overlay-checkbox {
  display: none;
}

input[type=checkbox].nav-overlay-checkbox:checked ~ #mobile-nav-overlay {
  display: block;
}

input[type=checkbox].nav-overlay-checkbox:checked ~ .nav-overlay-button:hover span, 
input[type=checkbox].nav-overlay-checkbox:checked ~ .nav-overlay-button span {
  background: transparent;
}
input[type=checkbox].nav-overlay-checkbox:checked ~ .nav-overlay-button span:before {
  transform: rotate(45deg) translate(4px, 7px);
  background: #000;
  opacity: 1;
}
input[type=checkbox].nav-overlay-checkbox:checked ~ .nav-overlay-button span:after {
  transform: rotate(-45deg) translate(4px, -7px);
  background: #000;
}

#mobile-nav-overlay {
  display: none;
  height: 100vh;
  width: 100vw;
  background:#fff;
  z-index: 1998;
  position: fixed;
  top:0;
  left: 0;
  overflow:hidden;
  overflow-y: scroll;
}
#mobile-nav-overlay.active {
  display: block;
}
#mobile-nav-overlay ul {
  flex-direction: column;
  text-align: center;
  padding-left: 0;
  list-style-type: none;
}
#mobile-nav-overlay ul li {
  padding: 0.5em;
}
#mobile-nav-overlay ul li a {
  color: #000;
  text-decoration: none;
  font-size: 1.5em;
  text-align: left;
}
#mobile-nav-overlay ul li a:hover {
  color: #8D8C8C;
}
