.product {
    margin-top: -1rem;
}


.product-input {
    text-transform: uppercase;
    font-size: 0.8em !important;
}

select.product-input {
    border-color: #999 !important;
}


.product-sideinfo {
    max-width: 280px;
    min-width: 250px;
}
.product-sideinfo .fixed-w {
    width:280px;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 767.98px) {
    .product-sideinfo .sticky-top {
        z-index: 50 !important;
    }
}
@media (min-width: 768px) {
    .product-sideinfo {
        height: 100vh;
    }

    .product-sideinfo > .sticky-top {
        top: 50%;
        transform: translate(0, -30%);
    }

}



.product-sideinfo h1 {
    font-size: 1.2rem;
    font-weight: 300;
}
.product-sideinfo h5 {
    font-weight: 300;
}
.product-sideinfo h2.price {
    font-size: 1rem;
    font-weight: 300;
}

/*
.product-sideinfo .test {
    height: calc(100vh - 2 * 5vw);
}
*/




/* ICONS */
.product-benfit {
    width:65px;
    margin-right: 5px;
    line-height: 1.4;
    background-color: #fff;
    height: 100%;
    font-weight: 400;
    /*
    text-transform: uppercase;
    font-size: 0.6em;
    */
}
.product-benfit-icon {
    width:40px;
    height:40px;
    display: block;
    margin: 0 auto 5px
}
.st0-shipping {
    display:none;
    fill:none;
    stroke:#000000;
    stroke-width:4;
    stroke-miterlimit:10;
}
.st1-shipping {
    fill:none;
    stroke:#000000;
    stroke-width:4;
    stroke-miterlimit:10;
}
.st3-ar {
    fill:none;
    stroke:#000000;
    stroke-width:1;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:10;
}



/*** gallery ***/
.productgallery .row > div {
    padding-left: 5px !important;
}
a.productgallery-img-wrapper {
    height: calc(100vh - 200px);
    min-width:100%;
    position: relative;
    overflow: hidden;
    /*
    margin: 0 -21px 60px -21px !important;
    margin: 0 -10px 60px -10px !important;
    */
    margin-bottom: 60px;
    /*cursor: zoom-in;*/
}
a.productgallery-img-wrapper picture {
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}
a.productgallery-img-wrapper img {
    display: block;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media (max-width: 767.98px) {
    .productgallery .row > div {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}
@media (min-width: 768px) {
    a.productgallery-img-wrapper {
        height: calc(100vh - 75px);
    }
}



/*** add to basket mobile ***/
.product-atb--mobile {
    position: fixed;
    bottom: 0;
    left:0;
    width:100%;
    background-color: #fff;
    z-index: 1080;

    -webkit-box-shadow: 0px -2px 30px 0px rgba(0,0,0,0.1); 
    box-shadow: 0px -2px 30px 0px rgba(0,0,0,0.1);
}
.product-atb--mobile .product-input {
    height: calc(1.5em + 0.75rem + 5px);
}
.product-atb--mobile select.addtobasket-sizeselect {
    width:60% !important;
    float: left;
}
.product-atb--mobile button.addtobasket-btn {
    float: right;
    width: calc(40% - 10px) !important;
    margin-left: 10px;
}
.product-atb--mobile form .collapse {
    float: none;
}
    .product-atb--mobile form .collapse a.product-input {
        line-height: 2;
    }
