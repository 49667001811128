.designerslist {
    font-size: 1.2em;
}
@media (min-width: 576px) { 
    .designerslist {
        columns: 2 auto;
    }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    .designerslist {
        max-width: 1000px;
        columns: 3 auto;
        font-size: 1.4em;
    }
}


