.sp-header {
    max-width: 600px;
    margin: 0 auto;
}
@media (min-width: 992px) { 
    .sp-header {
        margin-top: 100px;
        margin-left:30px;
    }
    .sp-header h1 {
        font-size: 5em;
        text-align: left;
        line-height: 0.9em;
    }
}

@media (min-width: 1200px) {
    .sp-header {
        margin-top: 50px;
        margin-left:30px;
    }
    .sp-header h1 {
        font-size: 10em;
    }    
}
@media (min-width: 1500px) {
    .sp-header {
        margin-left:auto;
    }
}


@media (min-width: 992px) { 
    h2.sp-section-header {
        font-size: 1.3rem;
    }
}





.wt-banner {
    margin-top: -1rem;
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}
.line-divider {
    width:1px;
    height: 80px;
    background-color: black;
    display: none;
}
@media (min-width: 576px) { 
    .line-divider {
        display: inline-block;
    }
}






h1.titlelrg {
    font-size: 3em;
}
h2.titlelrg {
    font-size: 2.4em;
}
h3.titlelrg {
    font-size: 2em;
}
@media (min-width: 992px) { 
    h1.titlelrg {
        font-size: 4em;
    }
    h2.titlelrg {
        font-size: 3em;
    }
    h3.titlelrg {
        font-size: 2.5em;
    }
}






.events-banner-txt {
        position: absolute;
        right:0;
        top:0;
        width:500px;
        height: 100%;
        text-transform: uppercase;
        font-size:2rem;
        line-height: 1;
        width:70%;
        background-color: rgba(255, 255, 255, 1);
        padding:30px;
        z-index: 2;
}
.events-banner-txt-m {
    font-size:1.2rem;
    display: none;
}
@media (orientation: portrait) and (max-width: 1030px) {
    .events-banner-txt {
        display: none !important;
    }
    .events-banner-txt-m {
        display: block;
    }
}
@media (orientation: landscape) and (max-width: 767.99px) {
    .events-banner-txt {
        max-width:250px;
        font-size:1rem;
    }
}
@media (orientation: landscape) and (min-width: 768px) and (max-width: 849.99px) {
    .events-banner-txt {
        max-width:330px;
        font-size:1.5rem;
    }
}
@media (min-width: 850px) {
    .events-banner-txt {
        width:450px;
    }
}
@media (min-width: 1200px) {
    .events-banner-txt {
        width:500px;
        font-size:2.4rem;
    }
}
@media (min-width: 1350px) {
    .events-banner-txt {
        width:550px;
        font-size:3rem;
    }
}
@media (min-width: 1500px) {
    .events-banner-txt {
        width:650px;
    }
}

@media (max-width: 767.99px) {
    .special-event-list .border-right {
        border: 0 !important;
    }
}

