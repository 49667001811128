.story .text-paragraph {
    line-height: 1.2 !important;
}

.music-box {
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    padding: 20px;
}

    .music-box .btn {
        width:200px;
    }


.ty-social-icon {
    width:28px;
    height:28px;
}
    .ty-social-icon.web {
        width:24px;
        height:24px;
    }
        .ty-social-icon.web path {
            stroke: #000000;
            stroke-width: 0px;
        }
    .ty-social-icon.f {
        width:26px;
        height:26px;
    }
    .ty-social-icon.x {
        width:22px;
        height:22px;
    }
        .ty-social-icon.x path {
            stroke: #000000;
            stroke-width: 1px;
        }